.project {
  font-size: 2em;
    transition: .5s;
    padding: 15px;
    width:80%;
    display: flex;
    justify-content: center;
    align-content: center;
}
.project:hover {
    margin-left: 3em;
    font-size: 2.5em;
    color: black;
    /*box-shadow:5px 10px #888888;*/
}
#main {
    /*background-image: url("wordcloud.png");*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/

}
.description {
    color: black;
    padding: 10px;
    margin-top:20%;
    position: absolute;
    width: 30%;
    /* left: 0; */
    right: 0;
    text-align: center;
    display: flex;
    font-size: 1.5em;

}
.image {
    bottom: 0;
    position: absolute;
    width:30vw;
    height:40vh;
}
.descriptionText {
    top:10%;
    display: block;
    width:20vw;
    position: absolute;
    right:0;
    margin:30px
}

/*.project {*/
/*    position: relative;*/
/*    display: inline-block;*/
/*}*/

/*.project::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    opacity: 0;*/
/*    transition: opacity 0.3s ease;*/
/*}*/

/*.project:hover::after {*/
/*    opacity: 1;*/
/*}*/

/*.project:nth-child(1)::after {*/
/*    background-image: url("https://netpes.net/ADMIN/chatbot.png");*/
/*}*/

/*.project:nth-child(2)::after {*/
/*    background-image: url("https://netpes.net/ADMIN/car.png");*/
/*}*/

/*.project:nth-child(3)::after {*/
/*    background-image: url("https://netpes.net/ADMIN/class.png");*/
/*}*/
